import HttpRequestHandler from "../HttpRequestHandler";
import {InsertObject} from "./InsertObject";
import {DeleteObject} from "./DeleteObject";
import {UpdateObject} from "./UpdateObject";
import {QueryObject} from "./QueryObject";


/**
 *
 * 要素服务封装类，可获取图层元数据，也可用来创建{@link InsertObject}、{@link DeleteObject}、{@link UpdateObject}、{@link QueryObject}，然后调用后台服务对要素（Feature）进行增、删、改、查
 * @class
 * @param opts
 */

export class FeatureService {
    private getToken: any;
    private wserver: any;
    private serviceUrl: string;
    private errorHandler:Function;

    /**
     * @private
     * @param opts
     */
    constructor(opts) {
        this.getToken = opts.getToken;
        this.wserver = opts.wserver;
        this.serviceUrl = opts.apiBasePath + '/featureService';

        this.errorHandler = function (rs) {
            throw new Error("error " + rs.error ? rs.error : "");
        };
    }


    /**
     * 设置错误回调函数
     * @function
     * @param errorHandler 该回调函数有1个参数，表示错误信息
     * @returns {void}
     */
    setErrorHandler(errorHandler:Function) {
        this.errorHandler = errorHandler;
    };


    private createCommonAjaxSuccessHandler(callback) {
        if (!callback) {
            throw new Error("callback不能为空");
        }
        return function (rs) {
            callback(rs);
        };
    };

    /**
     * 创建一个InsertObject，用来向指定图层添加要素。
     * @function
     * @param   layerName 图层名称
     * @returns
     */

    createInsertObject(layerName: string): InsertObject {
        let opts = {
            layerName: layerName,
            service: this
        };
        return new InsertObject(opts);
    };


    /**
     * 创建一个DeleteObject，用来从指定图层删除要素。
     * @function
     * @param layerName 图层名称
     * @returns
     */
    createDeleteObject(layerName: string): DeleteObject {
        let opts = {
            layerName: layerName,
            service: this
        };
        return new DeleteObject(opts);
    };


    /**
     * 创建一个UpdateObject，用来修改指定图层中的要素。
     * @function
     * @param  layerName 图层名称
     * @returns
     */
    createUpdateObject(layerName: string): UpdateObject {
        let opts = {
            layerName: layerName,
            service: this
        };
        return new UpdateObject(opts);
    };


    /**
     * 创建一个QueryObject，用来查询指定图层中的要素。
     * @function
     * @param  layerName 图层名称
     * @returns
     */
    createQueryObject(layerName: string): QueryObject {
        let opts = {
            layerName: layerName,
            service: this
        };
        return new QueryObject(opts);
    };


    /**
     * 获取指定图层的信息
     * @param layerName 图层名称
     * @param callback 成功回调函数
     */
    getLayer(layerName: string, callback: Function) {
        let me = this;

        function after() {
            let url = me.serviceUrl + "/" + layerName;
            let onSuccess = me.createCommonAjaxSuccessHandler(function (rs) {
                let layers = rs.layers;
                if (layers.length > 0) {
                    callback(layers[0], rs.privileges[0]);
                } else {
                    callback(null);
                }

            });
            HttpRequestHandler.get(url, me.getDefaultHeaders(), onSuccess, function () {
                throw new Error("getLayer Error");

            });
        }

        me.getToken(after);
    };


    /**
     * 获取多个图层的信息
     * @function
     * @param  layerNames 图层名称
     * @param callback 成功回调函数
     */
    getLayers(layerNames: string[], callback: Function) {
        let me = this;

        function after() {
            let url = me.serviceUrl + "/" + layerNames.join(",");
            let onSuccess = me.createCommonAjaxSuccessHandler(function (rs) {
                if (rs.layers) {
                    callback(rs.layers, rs.privileges);
                } else {
                    callback([rs.layer, rs.privilege]);
                }
            });

            HttpRequestHandler.get(url, me.getDefaultHeaders(), onSuccess, function () {
                throw new Error("getLayers error");
            });
        }

        me.getToken(after);
    };

    /**
     * 获取所有图层，不包含字段信息
     * @param callback 成功回调函数
     */
    getAllLayers(callback: Function) {
        let me = this;

        function after() {
            let url = me.serviceUrl;
            let onSuccess = me.createCommonAjaxSuccessHandler(function (rs) {
                callback(rs.layers, rs.privileges, {...rs});
            });
            HttpRequestHandler.get(url, me.getDefaultHeaders(), onSuccess, function () {
                throw new Error("getAllLayers");
            });
        }

        me.getToken(after);
    };

    /**
     * 根据当前用户信息，返回调用后台HTTP服务需要的请求头
     */
    getDefaultHeaders(): any {
        return this.wserver.getDefaultHeaders();
    }
}


