import HttpRequestHandler from "./HttpRequestHandler";


export class Logger {
    private track: boolean;
    private getToken: any;
    private wserver: any;
    private url: string;
    private data: any;

    /**
     * @private
     * @param opts
     * @param data
     */
    constructor(opts, data) {
        this.track = true;
        this.getToken = opts.getToken;
        this.wserver = opts.wserver;
        this.url = opts.apiBasePath + '/operationLogService';
        this.data = data;
    }


    /**
     * 记录日志
     * @param op
     * @param msg
     */
    log(op:string, msg:string) {
        const me = this;
        const writeLog = () => {
            if (!me.track) {
                return;
            }
            let {data, getDefaultModuleName} = me;

            const url = me.url + "/log";
            let module = data && data.module ? data.module : getDefaultModuleName();
            let postData = {
                mod: module,
                op: op,
                msg: msg || '',
                timestamp: new Date().getTime()
            };

            HttpRequestHandler.post(url, this.wserver.getDefaultHeaders(), postData, () => {
            }, () => {
            });
        };
        this.getToken(writeLog);
    }

    /**
     * 记录页面访问日志
     */
    logPageLoad() {
        this.log("访问页面", null);
    }

    /**
     * 获取当前模块名
     */
    getDefaultModuleName():string {
        return window.location.pathname;
    }
}



