import { sessionHelper } from "./sessionHelper";
import {Server} from "./Server";

export * from "./Server";
export {FeatureService} from "./featureservice/FeatureService";
export {DeleteObject} from "./featureservice/DeleteObject";
export {InsertObject} from "./featureservice/InsertObject";
export {QueryObject} from "./featureservice/QueryObject";
export {UpdateObject} from "./featureservice/UpdateObject";
export {PageQueryResult} from "./featureservice/PageQueryResult";

export {GeometryService} from "./GeometryService";


export {Logger} from "./Logger";
export {FileUploader} from "./FileUploader";



export {sessionHelper} from "./sessionHelper";


//兼容旧版，不推荐使用
window['WS'] = {
    WServer:Server,
    Server:Server,
    ... sessionHelper
}
