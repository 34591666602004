import {Server} from "./Server";

const LOGIN_MODE_DEFAULT = "default";
const LOGIN_MODE_ORG = "org";


function uuid() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
}

/**
 * 生成会员加密串
 * @param uuid
 * @param token
 * @param loginMode
 * @param userId
 * @param orgId
 */
function encodeSessionInfo(json) {
    if (!json.sessionId) {
        json.sessionId = uuid();
    }
    return window.btoa(JSON.stringify(json));
}


/**
 * 解析会话加密串
 * @param str
 */
function decodeSessionInfo(str) {
    return JSON.parse(window.atob(str));
}


function getSessionToken() {
    let params = new URL(document.location.toString()).searchParams;
    return params.get('tk');
}

function getSessionInfo() {
    let tk = getSessionToken();

    if (tk) {
        return decodeSessionInfo(tk);
    } else {

        return {};
    }
}


function appendQueryParam(url, key, value) {
    if (url.indexOf("?") > 0) {
        return url + "&" + key + "=" + value;
    } else {
        return url + "?" + key + "=" + value;
    }
}


function appendSessionToken(url, newToken) {

    newToken = newToken || getSessionToken();

    if (url.indexOf("tk=") > 0) {
        let params = new URL(url).searchParams;
        let oldToken = params.get('tk');
        return url.replace(oldToken, newToken);
    } else {
        return appendQueryParam(url, "tk", newToken);
    }
}

let WSERVER_INSTANCE;

function initWServer(opts) {
    opts = opts || {};

    let sessionInfo = getSessionInfo();

    if (opts.token === undefined && sessionInfo.token) {
        opts.token = sessionInfo.token;
    }
    if (opts.delegateOrgId === undefined && sessionInfo.orgId) {
        opts.delegateOrgId = sessionInfo.orgId;
    }

     WSERVER_INSTANCE = new Server(opts);
}


function getDefaultWServer(opts) {
    if (!WSERVER_INSTANCE) {
        initWServer(opts);
    }
    return WSERVER_INSTANCE;
}


function isDelegateMode() {
    return getSessionInfo().mode === LOGIN_MODE_ORG;
}


function getToken() {
    return getSessionInfo().token;
}


/**
 * @private
 */
export const sessionHelper = {
    getSessionToken,
    getSessionInfo,
    encodeSessionInfo,
    decodeSessionInfo,
    appendSessionToken,
    getDefaultWServer,
    isDelegateMode
}


