import HttpRequestHandler from "../HttpRequestHandler";

/**
 * 用来向某个图层中添加要素（Feature）
 * @class
 * @param opts
 */

export class InsertObject {
    private layerName: any;
    private service: any;
    private serviceUrl: string;
    private features: any[];

    /**
     * @private
     * @param opts
     */
    constructor(opts) {
        this.layerName = opts.layerName;
        this.service = opts.service;
        this.serviceUrl = opts.service.serviceUrl + "/" + opts.layerName + "/addFeatures";
        this.features = [];

    }



    /**
     * 在执行execute方法之前调用该方法，向待添加的要素数组中添加一个要素
     * @param feature GeoJSONFeature
     * @return
     */
    addFeature(feature:object) {
        this.features.push(feature);
    };

    /**
     * 在执行execute方法之前调用该方法，向待添加的要素数组中添加多个要素
     * @param features GeoJSONFeature数组
     * @return
     */
    addFeatures (features:object[]) {
        let len = features.length;
        for (let i = 0; i < len; i++) {
            this.addFeature(features[i])
        }
    };

    private _execute (features, callback) {
        let me = this;
        const {needCoordinateTransform, coordinateTransform2Remote} = this.service.wserver;
        const isFeatureNeedCoordinateTransform = needCoordinateTransform();

        if (isFeatureNeedCoordinateTransform && features) {
            features.map((el) => {
                el.geometry = coordinateTransform2Remote(el.geometry);
            })
        }

        function after() {
            let url = me.serviceUrl;
            let data = {
                layerName: me.layerName,
                features: JSON.stringify(features),
            };

            let onSuccess = me.service.createCommonAjaxSuccessHandler(function (rs) {
                callback(rs.ids);
            });

            HttpRequestHandler.post(url,me.service.getDefaultHeaders(), data, onSuccess)
        }

        me.service.getToken(after);

    };


    /**
     * 直接调用后台，添加一个要素
     * @param  feature GeoJSONFeature
     * @param  callback  第1个参数是自动生成的id
     *  @returns
     */
    insertFeature(feature:object, callback:Function) {
        this._execute([feature], function (ids) {
            let id = null;
            if (ids.length > 0) {
                id = ids[0];
            }
            callback(id);
        });
    };


    /**
     *
     * 直接调用后台添加多个要素
     * @param  features GeoJSONFeature数组
     * @param  callback 第1个参数是自动生成的id数组
     * @returns
     */
    insertFeatures(features:object[], callback:Function) {
        this._execute(features, callback);

    };


    /**
     *
     * 调用后台服务，将待添加的要素数组提交到后台。
     * @param callback 第1个参数是自动生成的id数组
     * @returns
     */
    execute(callback:Function) {
        this._execute(this.features, callback);
    };

    /**
     *
     * 设置待添加的要素数组
     * @param features GeoJSONFeature数组
     * @returns {void}
     */
    setFeatures(features:object[]) {
        this.features = features;
    }

    /**
     * 获取待添加的要素数组
     * @returns
     */
    getFeatures():object[] {
        return this.features;
    }
}


