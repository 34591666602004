import HttpRequestHandler from "../HttpRequestHandler";

/**
 * 用来修改某个图层中的要素（Feature）
 * @param opts
 * @constructor
 */
export class UpdateObject{
    private layerName: any;
    private service: any;
    private serviceUrl: string;
    private features: any[];

    /**
     * @private
     * @param opts
     */
    constructor(opts) {
        this.layerName = opts.layerName;
        this.service = opts.service;
        this.serviceUrl = opts.service.serviceUrl + "/" + opts.layerName + "/updateFeatures";
        this.features = [];

    }

    /**
     * 向待修改的要素数组中添加一个要素
     * @param feature GeoJSONFeature,必须包含id属性
     * @returns {void}
     */
    addFeature (feature:object) {
        this.features.push(feature);
    };

    /**
     *
     *  向待修改的要素数组中添加多个要素
     * @param features GeoJSONFeature数组,必须包含id属性
     * @returns {void}
     */
    addFeatures (features:object[]) {
        let len = features.length;
        for (let i = 0; i < len; i++) {
            this.addFeature(features[i])
        }
    };

    private _execute(features, callback) {
        let me = this;
        const {needCoordinateTransform, coordinateTransform2Remote} = this.service.wserver;
        const isFeatureNeedCoordinateTransform = needCoordinateTransform();

        if (isFeatureNeedCoordinateTransform && features) {
            features.map((el) => {
                el.geometry = coordinateTransform2Remote(el.geometry);
            })
        }

        function after() {
            let url = me.serviceUrl;
            let data = {
                dataFormat: "GeoJSON",
                features: JSON.stringify(features),
            };

            let onSuccess = me.service.createCommonAjaxSuccessHandler(function (rs) {
                callback();
            });

            HttpRequestHandler.post(url,me.service.getDefaultHeaders(), data, onSuccess, () => {
            })
        }

        me.service.getToken(after);
    };

    /**
     * 直接调用后台，更新一个要素
     * @param   feature GeoJSONFeature,必须包含id属性
     * @param  callback
     * @returns {void}
     */
    updateFeature(feature:object, callback:Function) {
        this._execute([feature], callback);
    };

    /**
     * 直接调用后台，更新多个要素
     * @param  features GeoJSONFeature数组,必须包含id属性
     * @param callback
     */
    updateFeatures(features:object[], callback:Function) {
        this._execute(features, callback);
    };

    /**
     * 调用后台服务，将待修改的要素数组提交到后台
     * @param callback
     */
    execute(callback:Function) {
        this._execute(this.features, callback);
    };


    /**
     *设置待修改的要素数组
     * @function
     * @param features GeoJSONFeature数组,必须包含id属性
     */
    setFeatures(features:object[]) {
        this.features = features;
    };

    /**
     * 获取待修改的要素数组
     * @function
     * @return
     */
    getFeatures () {
        return this.features;
    };

}

