function getWsdkConfig() {
    if (window['wsdk']) {
        return window['wsdk'].config;
    } else {
        return null;
    }
}

export function getDefaultToken(): string {
    return getWsdkConfig()?.getToken(true);
}

export function getDefaultDelegateOrgId(): string {
    return getWsdkConfig()?.getDelegateOrg(false);
}

export function getDefaultServerBasePath(): string {
    return getWsdkConfig()?.getServerBasePath(true);

}
