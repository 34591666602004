import HttpRequestHandler from "../HttpRequestHandler";


/**
 *
 * 用来从某个图层中删除要素（Feature）
 * @param opts
 * @constructor
 */

export class DeleteObject {
    private layerName: any;
    private service: any;
    private serviceUrl: string;
    private where: string;
    private geometry: any;

    /**
     * @private
     * @param opts
     */
    constructor(opts) {
        this.layerName = opts.layerName;
        this.service = opts.service;
        this.serviceUrl = opts.service.serviceUrl + "/" + opts.layerName + "/deleteFeatures";

        this.where = null;
        this.geometry = null;

    }


    /**
     * 设置执行删除时使用的where条件
     * @param {String} where
     * @returns {void}
     */
    setWhere(where: string) {
        this.where = where;
    };

    /**
     * 获取where条件
     * @returns  string
     */
    getWhere(): string {
        return this.where;
    }


    /**
     * 设置执行删除时使用的空间查询约束
     * @param  geometry GeoJSONGeometry
     * @returns {void}
     */
    setGeometry(geometry: object) {
        this.geometry = geometry;
    }

    /**
     *  获取空间查询约束
     * @returns
     */
    getGeometry(): any {
        return this.geometry;
    }


    private _execute(data, callback) {
        let me = this;
        const {needCoordinateTransform, coordinateTransform2Remote} = this.service.wserver;
        const isFeatureNeedCoordinateTransform = needCoordinateTransform();
        if (isFeatureNeedCoordinateTransform && data.geometry) {
            data.geometry = JSON.stringify(coordinateTransform2Remote(JSON.parse(data.geometry)));
        }

        function after() {
            let url = me.serviceUrl;
            data.dataFormat = "GeoJSON";

            let onSuccess = me.service.createCommonAjaxSuccessHandler(callback);
            HttpRequestHandler.post(url, me.service.getDefaultHeaders(), data, onSuccess, () => {
            })
        }

        me.service.getToken(after);

    };

    /**
     *
     * 根据id删除数据，直接调用后台执行删除。
     * @param   id  要素id
     * @param  callbak 成功回调函数
     * @returns
     */
    deleteById(id: number, callbak: Function) {
        let me = this;
        if (!id) {
            throw  new Error("id不能为空");
        }
        let data = {
            objectIds: id
        };

        me._execute(data, function () {
            callbak();
        });
    };


    /**
     *
     * 根据多个id删除数据，直接调用后台执行删除
     * @param   ids 要素id数组
     * @param callback  成功回调函数
     *  @returns {void}
     */
    deleteByIds(ids: number[], callback: Function) {
        let me = this;

        if (!ids || ids.length === 0) {
            throw  new Error("ids不能为空");
        }

        let data = {
            objectIds: ids.join(",")
        };

        me._execute(data, function () {
            callback();
        });
    };


    /**
     *
     * 根据之前设置的条件（where、geometry）执行删除操作，
     * @param callback 成功回调函数
     * @returns {void}
     */
    execute(callback: Function) {
        let me = this;
        if (!me.where && !me.geometry) {
            throw  new Error("where和geometry不能同时为空!")
        }

        let data = {
            where: me.where ? me.where : "",
            geometry: me.geometry ? JSON.stringify(me.geometry) : ""
        };
        me._execute(data, function () {
            callback();
        });
    };


}


