/**
 * HTTP请求工具类
 */
export default class HttpRequestHandler {

    /**
     * 异步get
     * @param url
     * @param headers
     * @param success
     * @param error
     */
    static get(url,headers, success, error) {
        this.ajax('GET', url, headers,null, true, success, error);
    };

    /**
     * post
     * @param url
     * @param headers
     * @param data
     * @param success
     * @param error
     */
    static post(url, headers?,data?, success?, error?) {
        this.ajax('POST', url, headers,data, true, success, error);
    };

    /**
     * 同步get
     * @param url
     * @param headers
     * @param success
     * @param error
     */
    static getAwait(url, headers,success, error) {
        this.ajax('GET', url, headers,null, false, success, error);
    };

    /**
     * 同步post
     * @param url
     * @param headers
     * @param data
     * @param success
     * @param error
     */
    static postAwait(url, headers,data, success, error) {
        this.ajax('POST', url, headers,data, false, success, error);
    };

    static ajax(type, url, headers,data, async, success, error) {
        const me = this;
        const xhr = new XMLHttpRequest();
        xhr.open(type, url, async);

        for(let key in headers){
            xhr.setRequestHeader(key,headers[key]);
        }

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    if (success) {
                        let rt = xhr.responseText;
                        if (rt) {
                            success(JSON.parse(xhr.responseText));
                        } else {
                            success();
                        }
                    }
                } else {
                    if (error) {
                        error();
                    }
                }
            }
        };
        if (type == "GET") {
            data = null;
        } else {
            data = me.convertToFormData(data);
            // for (let key of data.keys()) {
            //     console.log(key + "->" + data.get(key));
            // }
        }
        xhr.send(data);
        // setTimeout(function () {
        //
        // }, 30);
    };

    static convertToFormData(data){
        let formData = new FormData();
        for(let key in data){
            formData.append(key,data[key]);
        }
        return formData;
    }

    static convertDataToUrlData(data) {
        if (data === null) {
            return null;
        }
        let array = [];
        for (let key in data) {
            array.push(key + '=' + data[key]);
        }
        return array.join("&");
    }

    static convertFormDataToJson(data) {
        return JSON.parse(this.convertFormDataToJsonString(data));
    }

    static convertFormDataToJsonString(data) {
        data = decodeURIComponent(data);
        data = data.replace(/&/g, '","');
        data = data.replace(/=/g, '":"');
        data = '{"' + data + '"}';
        return data;
    }
}
