import {Logger} from "./Logger";
import HttpRequestHandler from "./HttpRequestHandler";
import {GeometryService} from "./GeometryService";
import CoordinateTransform from './CoordinateTransform';
import {FeatureService} from "./featureservice/FeatureService";
import {FileUploader} from "./FileUploader";
import {getDefaultDelegateOrgId, getDefaultServerBasePath, getDefaultToken} from "./defaultConfig";

const TOKEN_TIME_OUT = 60 * 1000; // 一小时


export type WServerOptions = {
    apiBasePath?: string;
    loginName?: string;
    password?: string;
    apiKey?: string;
    token?: string;
    delegateOrgId?: string;
    defaultServerCRS?: string;
    defaultClientCRS?: string;
}


export class Server {
    private apiBasePath: string;
    private loginName: string;
    private password: string;
    private apiKey: string;
    private token: string;
    private delegateOrgId: string;
    private createdByToken: boolean;
    private defaultServerCRS: string;
    private defaultClientCRS: string;
    private loginTime: Date;


    /**
     * opts中必须指定apiBasePath
     * token、apiKey、loginName和password三者必须指定一个。如果三者都未指定，会尝试从wsdk中获取token及delegateOrgId信息，
     */
    constructor(opts: WServerOptions) {

        opts = opts || {};

        this.apiBasePath = opts.apiBasePath || getDefaultServerBasePath();

        this.loginName = opts.loginName || null;
        this.password = opts.password || null;

        this.apiKey = opts.apiKey || null;

        this.token = opts.token || null;
        this.delegateOrgId = opts.delegateOrgId || null;


        if (!this.apiKey && !this.token) {
            this.token = getDefaultToken();
            if (!this.delegateOrgId) {
                this.delegateOrgId = getDefaultDelegateOrgId();
            }
        }


        this.createdByToken = !!opts.token;
        this.defaultServerCRS = opts.defaultServerCRS || null;
        this.defaultClientCRS = opts.defaultClientCRS || null;


    };


    getDefaultHeaders(): object {
        let headers = {};
        headers["3wgis-token"] = this.token;
        if (this.delegateOrgId) {
            headers['3wgis-delegate-org'] = this.delegateOrgId;
        }
        return headers;
    }


    createFeatureService(newOpts?): FeatureService {
        let opts = this.getOpts(newOpts);
        return new FeatureService(opts);
    }


    createGeometryService(newOpts?): GeometryService {
        let opts = this.getOpts(newOpts);
        return new GeometryService(opts);
    }

    createOpLogger(module: string,newOpts?): Logger {
        return new Logger(this.getOpts(newOpts), {module});
    }


    /**
     * 文件上传
     * @param module "/staticData"
     * @returns {FileUploader}
     */
    createFileUploader(module: string): FileUploader {
        if (!module) {
            throw new Error('module值不能为空！');
        }
        return new FileUploader(this.getOpts(), module);
    }


    /**
     * @private
     */
        // 生成参数对象
    getOpts = (newOpts?) => {
        return {
            delegateOrgId: this.delegateOrgId,
            apiBasePath: this.apiBasePath,
            getToken: this.getToken,
            wserver: this,
            ...newOpts
        };
    };

    /**
     * @private
     * @param callback
     */
    _getTokenByApiKey = (callback) => {
        const me = this;
        const {apiKey, getTokenUrl} = this;
        const data = {
            type: 'key',
            key: apiKey
        };
        let urlData = HttpRequestHandler.convertDataToUrlData(data);
        let url = getTokenUrl() + '?' + urlData;
        const success = (rs) => {
            me.token = rs.token;
            me.loginTime = new Date();
            callback();
        };
        HttpRequestHandler.get(url, null, success, (err) => {
            console.log(err.message)
        });
    };

    /**
     * @private
     * @param callback
     */
    _getTokenByLogin = (callback) => {
        const me = this;
        const {loginName, password, getTokenUrl} = this;
        const data = {
            type: 'password',
            loginName,
            password
        };
        let url = getTokenUrl();
        const success = (rs) => {
            me.token = rs.token;
            me.loginTime = new Date();
            callback();
        };
        HttpRequestHandler.post(url, null, data, success, (err) => {
            console.log(err.message)
        });
    };

    /**
     * @private
     * @param callback
     */
    _getToken = (callback) => {
        const {createdByToken, token, apiKey, loginName, password} = this;
        if (createdByToken) {
            callback();
        } else if (apiKey) {
            this._getTokenByApiKey(callback);
        } else if (loginName && password) {
            this._getTokenByLogin(callback);
        }
    };

    /**
     * @private
     * @param callBack
     */
        // 获取有效token
    getToken = (callBack) => {
        const {token, loginTime} = this;
        if (token) {
            let nowDate = new Date();
            if (loginTime) {
                let date = (nowDate.getTime() - this.loginTime.getTime()) / TOKEN_TIME_OUT;
                if (date <= 10) {
                    callBack();
                } else {
                    this._getToken(callBack);
                }
            } else {
                this.loginTime = nowDate;
                callBack();
            }
        } else {
            this._getToken(callBack);
        }
    };

    /**
     * @private
     */
    getTokenUrl = () => {
        return this.apiBasePath + '/auth/token';
    };

    /**
     * 远程坐标系转本地
     * @param data 经纬度数据 | geojson
     * @returns {*}
     */
    coordinateTransform2Local = (data) => {
        const {defaultServerCRS, defaultClientCRS} = this;
        return CoordinateTransform.transform(data, defaultServerCRS, defaultClientCRS);
    };

    /**
     *
     * @param data GeoJson数据
     * @param fromCRS 可取：'WGS84', 'GCJ02', 'BD09', 'CGCS2000'
     * @param toCRS 可取： 'WGS84', 'GCJ02', 'BD09', 'CGCS2000'
     */
    coordinateTransform(data,fromCRS,toCRS){
        return CoordinateTransform.transform(data, fromCRS, toCRS);
    };

    /**
     * 本地坐标系统转远程
     * @param data 经纬度数据 | geojson
     * @returns {*}
     */
    coordinateTransform2Remote = (data) => {
        const {defaultServerCRS, defaultClientCRS} = this;
        return CoordinateTransform.transform(data, defaultClientCRS, defaultServerCRS);
    };

    needCoordinateTransform = () => {
        const {defaultServerCRS, defaultClientCRS} = this;
        let flag = false;
        if (defaultServerCRS && defaultClientCRS) {
            const typeArray = ['WGS84', 'GCJ02', 'BD09', 'CGCS2000'];
            let realDefaultLocalCRS = defaultClientCRS === 'CGCS2000' ? 'WGS84' : defaultClientCRS;
            let realDefaultRemoteCRS = defaultServerCRS === 'CGCS2000' ? 'WGS84' : defaultServerCRS;
            if (typeArray.indexOf(realDefaultLocalCRS) !== -1 && typeArray.indexOf(realDefaultRemoteCRS) !== -1) {
                flag = realDefaultLocalCRS !== realDefaultRemoteCRS;
            } else {
                alert(`坐标转换配置错误：请修改配置文件中坐标转换参数（defaultClientCRS和defaultServerCRS），确保取值是['WGS84', 'GCJ02', 'BD09', 'CGCS2000']其中的一种！`);
            }
        }
        return flag;
    };
}

