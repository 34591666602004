import HttpRequestHandler from "./HttpRequestHandler";
import {Server} from "./Server";


/**
 * 几何服务封装类，通过后台服务创建缓冲区
 * @class WS.GeometryService
 * @param {String} serviceUrl  http://[host]:[port]/rest/GeometryService/defaultService
 */
export class GeometryService {
    private serviceUrl: string;
    private wserver: Server;

    /**
     * @private
     * @param props
     */
    constructor(props) {
        this.serviceUrl = props.apiBasePath + '/geometryService';
        this.wserver = props.wserver;
    }

    /**
     * @private
     * @param rs
     */
    errorHandler = (rs) => {
        throw new Error("error " + rs.error ? rs.error : "");
    };


    /**
     * 设置错误回调函数，当生成缓冲发生错误时会调用该函数。
     * @param  callback 该回调函数有1个参数，表示错误信息
     * @return {void}
     */
    setErrorHandler(callback:Function) {
        // @ts-ignore
        this.errorHandler = callback;
    };

    private _createCommonAjaxSuccessHandler = (callback) => {
        const me = this;
        if (!callback) {
            throw  new Error("callback不能为空");
        }
        return function (rs) {
            if (rs) {
                callback(rs);
            } else {
                me.errorHandler(rs);
            }
        };
    };

    /**
     *
     * 调用后台服务，生成一个缓冲区
     * @function
     * @param  geom  GeoJSONGeometry,原始几何对象，
     * @param  distance  缓冲距离，单位为米
     * @param  callback 该回调函数有1个参数，第1个参数为{@link Polygon}类型
     *
     * @return {void}
     */
    createBuffer(geom:object, distance:number, callback:Function) {
        this.createBuffers([geom], [distance], function (geoms) {
            let data = null;
            if (geoms.length > 0) {
                data = geoms[0];
            }
            callback(data);
        });
    };


    /**
     *
     * 调用后台服务，同时生成多个缓冲区
     * @function
     * @param  geoms  GeoJSONGeometry数组，原始几何对象
     * @param distances  缓冲距离，单位米。每个几何对象单独指定缓冲距离。
     * @param  callback  该回调函数有1个参数，第1个参数为{@link GeoJSONPolygon}数组
     * @return {void}
     */
    createBuffers(geoms:object[], distances:number[], callback:Function) {
        if (geoms.length !== distances.length) {
            throw new Error("geoms和distances长度必须一致")
        }
        const me = this;
        let url = me.serviceUrl + "/buffer";
        let data = {
            geometries: JSON.stringify(geoms),
            distances: distances.join(",")
        };
        let onSuccess = me._createCommonAjaxSuccessHandler(function (rs) {
            callback(rs);
        });
        HttpRequestHandler.post(url, me.wserver.getDefaultHeaders(), data, onSuccess, me.errorHandler);
    };
}


