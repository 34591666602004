import HttpRequestHandler from "../HttpRequestHandler";
import {PageQueryResult} from "./PageQueryResult";

/**
 *
 * 用来查询图层中的要素（Feature）
 * @param opts
 * @constructor
 */

export class QueryObject {
    private layerName: any;
    private service: any;
    private serviceUrl: string;
    private where: string;
    private bufferDistance: number;
    private geometry: any;
    private orderByFields: string;
    private outFields: string;
    private returnGeometry: boolean;
    private pageSize: number;
    private startIndex: number;
    private maxFeatures: number;

    /**
     * @private
     * @param opts
     */
    constructor(opts) {
        this.layerName = opts.layerName;
        this.service = opts.service;
        this.serviceUrl = opts.service.serviceUrl + "/" + opts.layerName + "/query";

        this.where = null;
        this.bufferDistance = 0;
        this.geometry = null;

        this.orderByFields = null;

        this.outFields = "*";

        this.returnGeometry = true;


        this.pageSize = 20;

        this.startIndex = 0;
        this.maxFeatures = 5000;

    }

    getMaxFeatures() {
        return this.maxFeatures;
    }

    getStartIndex() {
        return this.startIndex;
    }

    setMaxFeatures(value) {
        this.maxFeatures = value;
    }

    setStartIndex(value) {
        this.startIndex = value;
    }


    /**
     * 设置查询时的where条件
     * @param  where
     */
    setWhere(where: string) {
        this.where = where;
    };

    /**
     * 获取where条件
     * @returns
     */
    getWhere(): string {

        return this.where;
    };

    /**
     * 设置缓冲半径，单位为米
     * @param distance
     */
    setBufferDistance(distance: number) {
        this.bufferDistance = distance;
    };

    /**
     * 获取缓冲半径，单位为米
     * @returns
     */
    getBufferDistance(): number {
        return this.bufferDistance;
    }

    /**
     * 设置查询时的空间约束
     * @param  geometry GeoJSONGeometry
     * @returns {void}
     */
    setGeometry(geometry: object) {
        this.geometry = geometry;
    }

    /**
     * 获取空间约束
     * @returns
     */
    getGeometry(): object {
        return this.geometry;
    }

    /**
     *
     * 设置查询结果中是否包括空间位置信息，为true则包含，为false不包含。默认为true
     * @param returnGeometry
     */
    setReturnGeometry(returnGeometry: boolean) {
        this.returnGeometry = returnGeometry;
    };

    /**
     * 获取查询结果是是否包含空间位置信息
     * @returns
     */
    getReturnGeometry(): boolean {
        return this.returnGeometry;
    };


    /**
     *  设置查询时返回的字段。可使用"*" 表示返回所有字段；指定多个字段时使用“,”分隔字段名
     * @param  outFields  如 * , 'colA'
     * @returns {void}
     */
    setOutFields(outFields: string) {
        this.outFields = outFields;
    };


    /**
     * 获取要返回的字段。
     * @returns
     */
    getOutFields(): string {
        return this.outFields;
    };


    /**
     * 设置查询时的排序字段。
     * @param orderByFields 如 colA   ; colA asc   ; col desc   ; colA asc ,colB desc ;
     * @returns
     */
    setOrderByFields(orderByFields: string) {
        this.orderByFields = orderByFields;
    };

    /**
     * 获取查询时的排序字段
     * @returns
     */
    getOrderByFields(): string {
        return this.orderByFields;
    };


    /**
     * 设置分页查询时每页的条数
     * @param  pageSize 该值在1~5000之间
     * @returns
     */
    setPageSize(pageSize: number) {
        pageSize = +pageSize;
        if (pageSize > 5000) {
            throw  new Error("pageSize不能大于5000");
        }
        if (pageSize < 1) {
            throw  new Error("pageSize不能小于1");
        }
        this.pageSize = pageSize;
    };

    /**
     * 获取分页查询时每页的条数
     * @function
     * @returns
     */
    getPageSize(): number {
        return this.pageSize;
    };


    private _execute(data, callback) {


        const {needCoordinateTransform, coordinateTransform2Local, coordinateTransform2Remote} = this.service.wserver;
        const isFeatureNeedCoordinateTransform = needCoordinateTransform();

        let me = this;
        if (isFeatureNeedCoordinateTransform && data.geometry) {
            data.geometry = JSON.stringify(coordinateTransform2Remote(JSON.parse(data.geometry)));
        }

        function after() {

            function handleResult(rs) {
                if (isFeatureNeedCoordinateTransform && rs && rs.features) {
                    rs.features.map((el) => {
                        let geom = el.geometry;
                        el.geometry = coordinateTransform2Local(geom);
                    });
                    if (rs.bufferedGeometry) {
                        rs.bufferedGeometry = coordinateTransform2Local(rs.bufferedGeometry);
                    }
                }
                callback(rs);
            }

            let url = me.serviceUrl;
            data.dataFormat = "GeoJSON";

            let onSuccess = me.service.createCommonAjaxSuccessHandler(handleResult);

            HttpRequestHandler.post(url, me.service.getDefaultHeaders(), data, onSuccess, () => {
            })
        }

        me.service.getToken(after);
    };


    /**
     * 直接调用后台服务，根据ID查询
     * @param id
     * @param  callback 第1个参数是{@link Feature}对象。
     * @returns {void}
     */
    queryById(id: number, callback: Function) {
        let me = this;

        if (!id) {
            throw  new Error("id不能为空");
        }

        let data = {
            objectIds: id,
            returnGeometry: me.returnGeometry,
            outFields: me.outFields
        };

        me._execute(data, function (rs) {
            let features = rs.features;
            let data = null;
            if (features.length > 0) {
                data = features[0];
            }
            callback(data);
        });
    };

    /**
     * 直接调用后台服务，根据多个id查询
     * @param  ids
     * @param  callback   第1个参数是{@link Feature}数组
     * @returns {void}
     */
    queryByIds(ids: number[], callback: Function) {
        let me = this;

        if (!ids || ids.length === 0) {
            throw  new Error("ids不能为空");
        }

        let data = {
            objectIds: ids.join(","),
            returnGeometry: me.returnGeometry,
            outFields: me.outFields,
            orderByFields: me.orderByFields ? me.orderByFields : ""
        };
        me._execute(data, function (rs) {
            callback(rs.features);
        });
    };

    /**
     *
     * 使用之前设置的参数,调用后台服务，执行查询，
     * @function
     * @param  callback  第1个参数是{@link Feature}数组
     * @returns {void}
     */
    execute(callback: Function) {
        let me = this;
        let data = {
            returnGeometry: me.returnGeometry,
            outFields: me.outFields,
            orderByFields: me.orderByFields ? me.orderByFields : "",
            bufferDistance: me.bufferDistance,
            geometry: me.geometry ? JSON.stringify(me.geometry) : "",
            where: me.where ? me.where : "",
            startIndex: me.startIndex,
            maxFeatures: me.maxFeatures
        };


        me._execute(data, function (rs) {
            callback(rs.features, rs.bufferedGeometry);
        });
    };

    /**
     *
     * 使用之前设置的参数,调用后台服务，执行分页查询。回调结果是{@link PageQueryResult}
     * @function
     * @param  callback   第一个参数是{@link PageQueryResult}对象
     * @returns {void}
     */
    executeWithPage(callback: Function) {
        let me = this;

        let data = {
            returnGeometry: false,
            returnIdsOnly: true,
            maxFeatures: 100000,
            orderByFields: me.orderByFields ? me.orderByFields : "",
            bufferDistance: me.bufferDistance,
            geometry: me.geometry ? JSON.stringify(me.geometry) : "",
            where: me.where ? me.where : ""
        };

        me._execute(data, function (rs) {
            let ids = rs.objectIds;
            let pageSize = me.pageSize;
            let total = rs.count;

            let opts = {
                ids: ids,
                bufferedGeometry: rs.bufferedGeometry,
                pageSize: pageSize,
                total: total,
                queryObj: me,
            };
            callback(new PageQueryResult(opts));
        });
    };

}

