/**
 * 封装分页查询结果。
 *
 * 页号从1开始。
 * @param opts
 * @constructor
 */

export class PageQueryResult {
    private _opts: any;
    private pageSize: any;
    private total: any;
    private currentPageNumber: number;
    private totalPage: number;

    /**
     * @private
     * @param opts
     */
    constructor(opts) {
        this._opts = opts;
        this.pageSize = opts.pageSize;
        this.total = opts.total;
        this.currentPageNumber = 1;
        this.totalPage = Math.ceil(this.total * 1.0 / this.pageSize);

    }

    /**
     *
     * 返回总条数
     * @returns
     */
    getTotal():number {
        return this.total;
    };


    /**
     *
     * 返回当前页号
     * @returns
     */
    getCurrentPageNumber():number {
        return this.currentPageNumber;
    }

    /**
     *
     * 返回每面条数
     * @returns
     */
    getPageSize():number {
        return this.pageSize;
    };


    /**
     * 缓冲查询时生成的缓冲区
     */
    getBufferedGeometry():object {
        return this._opts.bufferedGeometry;
    };

    /**
     *
     * 返回总页数
     * @returns
     */
    getTotalPage():number {
        return this.totalPage;
    };

  private  _getIds(pageNumber) {
        let me = this;

        let pageSize = me.pageSize;
        let begin = (pageNumber - 1) * pageSize;
        let end = begin + pageSize;
        let ids = me._opts.ids;
        if (end > ids.length) {
            end = ids.length;
        }

        return ids.slice(begin, end);
    };

    /**
     * 获取指定页数的数据
     * @param  pageNumber
     * @param callback 第1个参数是{@link Feature}数组，第2个参数是页号
     * @returns {void}
     */
    getPage(pageNumber:number, callback:Function) {
        let me = this;
        let totalPage = me.totalPage;

        if (totalPage == 0) {
            callback([], 0);
            return;
        }

        let opts = me._opts;

        let queryObj = opts.queryObj;

        if (pageNumber < 1) {
            pageNumber = 1;
        }

        if (pageNumber > totalPage) {
            pageNumber = totalPage;
        }


        let pageSize = me.pageSize;

        let startIndex = (pageNumber - 1) * pageSize;
        let maxFeatures = pageSize;

        let endIndex = startIndex + maxFeatures;
        if (endIndex > me.total) {
            endIndex = me.total;
        }

        let data:any = {
            returnGeometry: queryObj.returnGeometry,
            outFields: queryObj.outFields
        };

        let ids = me._opts.ids;


        if (startIndex <= ids.length - 1 && endIndex <= ids.length) {
            let reqIds = ids.slice(startIndex, endIndex);
            data.objectIds = reqIds.join(',');

        } else {
            data.startIndex = startIndex;
            data.maxFeatures = maxFeatures;
            data.orderByFields = queryObj.orderByFields ? queryObj.orderByFields : "";
            data.bufferDistance = queryObj.bufferDistance;
            data.geometry = queryObj.geometry ? JSON.stringify(queryObj.geometry) : "";
            data.where = queryObj.where ? queryObj.where : "";

        }


        let newCallback = function (rs) {
            callback(rs.features, pageNumber);
        };

        queryObj._execute(data, newCallback);
    };


    /**
     * 跳转到指定页，会修改currentPageNumber
     * @param pageNumber
     * @param callback 第1个参数是{@link Feature}数组
     * @returns {void}
     */
    gotoPage(pageNumber:number, callback:Function) {
        let me = this;
        me.getPage(pageNumber, function (rs, pageNumber) {
            me.currentPageNumber = pageNumber;
            callback(rs);
        });
    };

    /**
     * 获取当前页数据
     * @param callback callback 第1个参数是{@link Feature}数组
     * @returns {void}
     */
    currentPage(callback:Function) {
        this.gotoPage(this.currentPageNumber, callback);
    };

    /**
     * 获取上一页数据
     * @param callback callback 第1个参数是{@link Feature}数组
     * @returns {void}
     */
    prePage(callback:Function) {
        this.gotoPage(this.currentPageNumber - 1, callback);
    };

    /**
     * 获取下一页数据
     * @param callback callback 第1个参数是{@link Feature}数组
     * @returns {void}
     */
    nextPage(callback:Function) {
        this.gotoPage(this.currentPageNumber + 1, callback);
    };

    /**
     * 获取第一页数据
     * @param callback callback 第1个参数是{@link Feature}数组
     * @returns {void}
     */
    firstPage(callback:Function) {
        this.gotoPage(1, callback);
    };

    /**
     * 获取最后一页数据
     * @param callback callback 第1个参数是{@link Feature}数组
     * @returns {void}
     */
    lastPage(callback:Function) {
        this.gotoPage(this.totalPage, callback);
    };


    /**
     * 自动遍历 beginPage到endPage之间的页面
     * @param beginPage
     * @param endPage
     * @param pageCallback 每页加载完成的回调函数,回调函数第1个参数是页号，第2个参数是本页数据。
     * @param endCallback 完成所有遍历后的回调函数
     */
    autoLoadPage(beginPage:number, endPage:number, pageCallback:Function, endCallback:Function) {

        let autoGetNextPage = function (pageResult, currentPage, maxPage, pageCallback, endCallback) {
            if (currentPage > maxPage) {
                endCallback();
            } else {
                pageResult.getPage(currentPage, function (features) {
                    pageCallback(currentPage, features);
                    autoGetNextPage(pageResult, ++currentPage, maxPage, pageCallback, endCallback);
                });
            }
        };

        let autoLoadAllPage = function (pageResult, beginPage, endPage, pageCallback, endCallback) {
            let totalPage = pageResult.getTotalPage();
            if (endPage > totalPage) {
                endPage = totalPage;
            }
            if (beginPage < 1) {
                beginPage = 1;
            }
            if (endPage >= beginPage) {
                autoGetNextPage(pageResult, beginPage, endPage, pageCallback, endCallback);
            } else {
                endCallback();
            }
        };

        autoLoadAllPage(this, beginPage, endPage, pageCallback, endCallback);

    };
}

