import HttpRequestHandler from "./HttpRequestHandler";

const SHARD_SIZE = 5 * 1024 * 1024; //5M （上传文件分片大小）

// 上传接口定义
const UPLOAD_API_URL = {
    upload: '/upload', // 单文件上传
    prepareShardUpload: '/prepareShardUpload', // 分片开始
    shardUpload: '/shardUpload', // 分片上传
    finishShardUpload: '/finishShardUpload', // 分片结束
};

/**
 * 文件上传
 */
export   class FileUploader {
    private getToken: any;
    private apiBasePath: any;
    private wserver: any;
    private _module: any;
    private _header: null;
    private _files: any[];
    private _fileIds: any[];
    private _fileIndex: number;
    private _shardIndex: number;
    private _shardId: null;
    private _uploadSuccess: boolean;

    /**
     * @private
     * 构造函数
     * @param opts
     * @param module 上传对应的模块
     */
    constructor(opts, module) {
        this.getToken = opts.getToken;
        this.apiBasePath = opts.apiBasePath;
        this.wserver = opts.wserver;
        this._module = module;
        this._header = null;
        // 上传指针变量
        this._files = []; // 待上传文件
        this._fileIds = []; // 文件上传返回的ID数组
        this._fileIndex = 0; // 当前上传文件下标
        this._shardIndex = 0; // 当前上传分片下标
        this._shardId = null; // 当前分片ID
        this._uploadSuccess = true; // 上传是否成功，默认成功，异常时修改为false，终止上传
    }

    /**
     * 批量上传文件，如果单文件超过SHARD_SIZE时，会自动执行分片上传。
     * @param files
     * @param options
     *        options.onProcess({fileIndex,process,file})
     *              fileIndex:从0开始，
     *              process，文件上传开始时该值为0，文件上传结束时该值为1，如果是分片上传，可能有中间值
     *              file:文件对象
     *        options.onSuccess(ids)   所有文件上传结束时调用该方法
     *              ids:对象数组
     *        options.onError(err)     发生错误时调用该方法
     *              err:错误信息
     */
    uploadFiles(files:File[], options:{onProcess:Function,onError:Function,onSuccess:Function}) {
        const me = this;
        const {onProcess, onSuccess, onError} = options;
        const hFileData = this._handleFiles(files);
        const {sFiles, total} = hFileData;

        // 生成上传进度返回给调用都的参数
        const genProcessRsData = (fileIndex, file, oneFileProcess) => {
            return {
                fileIndex,
                process: oneFileProcess,
                file
            }
        };

        // 错误统一处理
        const errorHandler = (msg) => {
            me._uploadSuccess = false;
            if (onError) {
                onError({msg});
            } else {
                console.log(msg);
            }
        };

        // 进行回调处理
        const processHandler = (rs) => {
            onProcess(rs);
        };

        const onUploadSuccess = () => {
            if (!me._uploadSuccess) {
                return;
            }
            if (me._fileIndex < me._files.length) {
                doUpload();
            } else {
                const sm = {msg: '上传完成！', ids: me._fileIds};
                if (onSuccess) {
                    onSuccess(sm);
                } else {
                    console.log(sm);
                }
            }
        };

        const doUpload = () => {
            const {_files: sFiles, _fileIndex: i, _shardIndex: sIndex, _shardId} = me;
            const {file, isShard, shards, total: sTotal} = sFiles[i];
            if (!isShard) { // 普通单个上传
                processHandler(genProcessRsData(i, file, 0));
                me._shardIndex = 0; // 普通上传，分片下标归零
                me._uploadFileAsync(file, (id) => {
                    me._fileIds.push(id); // 将ID添加到结果数组
                    processHandler(genProcessRsData(i, file, 1));
                    me._fileIndex = i + 1;
                    onUploadSuccess();
                }, (err) => {
                    errorHandler('上传失败!');
                });
            } else { // 分片上传
                // 分片请求开始
                let sId = _shardId;
                if (sIndex === 0) {
                    sId = me._prepareShardUpload(file); // 通知后台开始分片，获取ID
                    if (!sId) {
                        errorHandler('预分片失败!');
                        return;
                    }
                    me._shardId = sId;
                    processHandler(genProcessRsData(i, file, 0));
                }
                if (!sId) {
                    errorHandler('分片ID获取异常!');
                    return;
                }
                // 分片上传
                const {index, data} = shards[sIndex];
                // 上传分片
                me._shardUploadAsync(sId, (index + 1), data, () => {
                    // 后台无返回值
                    if (index === sTotal - 1) {
                        // 分片请求结束
                        const fS = me._finishShardUpload(sId); // 通知后台结束分片
                        if (!fS) {
                            errorHandler(`上传分片结束调用失败!`);
                        }
                        me._fileIds.push(sId); // 将ID添加到结果数组
                        processHandler(genProcessRsData(i, file, 1));
                        me._fileIndex = i + 1;
                        me._shardIndex = 0;
                    } else {
                        const pN = Number(((sIndex + 1) / (sTotal + 0.0)).toFixed(2));
                        processHandler(genProcessRsData(i, file, pN));
                        me._shardIndex = index + 1;
                    }
                    onUploadSuccess();
                }, () => {
                    errorHandler(`上传分片失败!No:${index}`);
                });
            }

        };

        const after = async () => {
            // 指针归零
            me._files = sFiles;
            me._fileIds = [];
            me._fileIndex = 0;
            me._shardIndex = 0;
            me._shardId = null;
            me._uploadSuccess = true;
            // 开始上传
            doUpload();
        };
        // 验证token后执行具体的上传操作
        this._checkToken(after);
    }

    /**
     *
     * Token验证更新
     * @param callback
     * @private
     */
    _checkToken(callback) {
        const me = this;
        const after = () => {
            me._header = me.wserver.getDefaultHeaders();
            callback();
        };
        me.getToken(after);
    }

    /**
     * 多文件预处理
     * @param files
     * @returns {{total: *, sFiles: *}}
     * @private
     */
    _handleFiles(files) {
        const me = this;
        let total = 0; // 真实上传文件次数
        let sFiles = []; // 真实上传文件数组
        files.map((file) => {
            const sFile = me._doShard(file);
            const {total: sTotal} = sFile;
            total += sTotal;
            sFiles.push(sFile);
        });
        return {
            sFiles,
            total
        }
    }

    /**
     * 单个文件处理
     * @private
     */
    _doShard(file) {
        let rsFile = {
            file: file, // 原文件
            isShard: false, // 是否分片
            shards: [], // 分片
            total: 1, // 总文件数
        };
        const fileSize = file.size;
        if (fileSize > SHARD_SIZE) {// 对文件进行分片
            let shards = [];
            const shardCount = Math.ceil(fileSize * 1.0 / SHARD_SIZE); // 分片数
            for (let i = 0; i < shardCount; i++) {
                const begin = i * SHARD_SIZE;
                const end = (i + 1) * SHARD_SIZE;
                const shard = {
                    index: i, // 分片索引
                    data: file.slice(begin, end) // 分片文件数据
                };
                shards.push(shard);
            }
            rsFile = {
                file: file,
                isShard: true,
                shards: shards,
                total: shardCount,
            }
        }
        return rsFile;
    }

    /**
     * 上传单个文件（异步）
     * @private
     */
    _uploadFileAsync(file, success, error) {
        const {url, header} = this._getUrlAndHeader(UPLOAD_API_URL.upload);
        HttpRequestHandler.post(url, header, {file}, (rs) => {
            success(rs.id);
        }, (err) => {
            if (error) {
                error(err);
            } else {
                console.log(err);
            }
        });
    }

    /**
     * 预分片（同步）
     * @private
     */
    _prepareShardUpload(file) {
        const {url, header} = this._getUrlAndHeader(UPLOAD_API_URL.prepareShardUpload);
        let id = null;
        const {name, size, type} = file;
        const data = {
            fileName: name,
            contentType: type,
            length: size,
            shardSize: SHARD_SIZE
        };
        HttpRequestHandler.postAwait(url, header, data, (rs) => {
            id = rs.id;
        }, (err) => {
            console.log(err);
        });
        return id;
    }

    /**
     * 上传单个分片（异常）
     * @param id
     * @param index
     * @param shardData
     * @param success
     * @param error
     * @private
     */
    _shardUploadAsync(id, index, shardData, success, error) {
        const {url, header} = this._getUrlAndHeader(UPLOAD_API_URL.shardUpload);
        const data = {
            id,
            shardNumber: index,
            data: shardData
        };
        HttpRequestHandler.post(url, header, data, (rs) => {
            success();
        }, (err) => {
            if (error) {
                error(err);
            } else {
                console.log(err);
            }
        });
    }

    /**
     * 结束分片
     * @param id
     * @returns {boolean}
     * @private
     */
    _finishShardUpload(id) {
        const {url, header} = this._getUrlAndHeader(UPLOAD_API_URL.finishShardUpload);
        let success = false;
        const data = {
            id
        };
        HttpRequestHandler.postAwait(url, header, data, (rs) => {
            success = true;
        }, (err) => {
            console.log(err);
        });
        return success;
    }

    /**
     * 获取请求URL和Header信息
     * @param method
     * @returns {{header: *, url: *}}
     * @private
     */
    _getUrlAndHeader(method) {
        const {apiBasePath: bathPath, _module: module, _header: header} = this;
        const url = `${bathPath}${module}${method}`;
        return {url, header};
    }
}
